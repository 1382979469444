<template>
  <div>
    <summary-cupo-quota />
  </div>
</template>
<script>
import SummaryCupoQuota from '@/components/InfoCupo/SummaryCupoQuota'
export default {
  components: {
    SummaryCupoQuota,
  },
}
</script>
